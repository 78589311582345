import Rails from "@rails/ujs"
import { Controller } from "stimulus"
import Timeout from 'smart-timeout'
import PageController from './page_controller'

export default class extends PageController
  @targets = [ "container" ]

  renderUser: (event, user_id='') =>
    if user_id == ''
      target = event.currentTarget
      user_id = target.dataset.userId
    container = document.getElementById("user_#{user_id}")
    Rails.ajax
      type: "GET"
      url: "/utenti/#{user_id}/utente"
      success: (response, status, xhr) =>
        container.outerHTML = xhr.response if container
      error: ( error ) =>
        this.send "Si è verificato un errore durante il caricamento! Si prega di provare più tardi.", "error"

  edit: (event) =>
    target = event.target
    if target.classList.contains('editable')
      container = target.parentNode
      userId = target.dataset.userId
      editDiv = document.createElement("DIV")
      editDiv.id = "#{userId}_editor"
      editDiv.className = "editor"
      container.appendChild(editDiv)
      oldDate = target.text
      if target.dataset.hasOwnProperty('fieldType')
        if target.dataset.fieldType == 'textarea'
          el = document.createElement("TEXTAREA")
          el.className = "textarea editor is-radiusless"
          el.style = 'width:80%;'
          el.innerHTML = target.dataset.fieldValue
        else if target.dataset.fieldType == 'select'
          el = document.createElement("SELECT")
          el.className = "select editor is-radiusless"
          el.style = "width:60%;"
          options = target.dataset.fieldOptions.split(',')
          if target.dataset.fieldPrompt
            opt = document.createElement("option")
            opt.value = ""
            opt.text = target.dataset.fieldPrompt
            opt.selected = true if target.dataset.fieldValue == ""
            el.appendChild(opt)
          for option in options
            opt = document.createElement("option")
            if option.indexOf("||") >= 0
              option_splitted = option.split("||")
              opt.value = option_splitted[0]
              opt.text = option_splitted[1]
            else
              opt.value = option
              opt.text = option
            opt.selected = true if option == target.dataset.fieldValue
            el.appendChild(opt)
        else if target.dataset.fieldType == 'file'
          user_id = target.dataset.userId
          form = document.createElement("FORM")
          form.method = 'post'
          form.action = "/utenti/#{ user_id }"
          form.dataset.multipart = 'true'
          form.dataset.remote = ''
          form.acceptCharset = 'UTF-8'
          form.dataset.action = 'ajax:success->users#abort'
          el = document.createElement("INPUT")
          el.type = 'hidden'
          el.name = '_method'
          el.value = 'put'
          form.appendChild(el)
          el = document.createElement("INPUT")
          el.name = target.dataset.fieldName
          el.type = target.dataset.fieldType
          el.className = "input #{ target.dataset.fieldType } editor is-radiusless"
          el.style = "width:60%;"
          el.accept = target.dataset.fieldAccept || ''
          # el.dataset.directUpload = target.dataset.fieldDirectUpload || 'false'
          form.appendChild(el)
        else
          el = document.createElement("INPUT")
          el.type = target.dataset.fieldType
          el.className = "input #{ target.dataset.fieldType } editor is-radiusless"
          el.style = "width:60%;"
          el.value = target.dataset.fieldValue
      else
        el = document.createElement("INPUT")
        el.className = "input editor is-radiusless"
        el.style = "width:60%;"
        el.value = target.dataset.fieldValue
      if target.dataset.hasOwnProperty('fieldPattern')
        el.pattern = target.dataset.fieldPattern
        el.addEventListener 'change', (event) =>
          if el.validity.typeMismatch
            el.setCustomValidity("Valore inserito non valido!")
          else
            el.setCustomValidity("")
      if target.dataset.hasOwnProperty('fieldRequired')
        el.required = 'required'
      unless target.dataset.fieldType == 'file'
        el.placeholder = target.dataset.fieldPlaceholder
        el.name = target.dataset.fieldName
        editDiv.appendChild(el)
        btnSave = document.createElement("BUTTON")
        btnSave.innerHTML = "<i class='fa fa-save' style=#{ if target.dataset.fieldType == 'textarea' then '' else 'padding-right:0px' }></i>#{ if target.dataset.fieldType == 'textarea' then ' Salva' else '' }"
        btnSave.className = "button tooltip is-transparent is-borderless is-radiusless"
        btnSave.dataset.tooltip = "Salva"
        btnSave.dataset.action = "click->users#update"
        btnSave.dataset.userId = userId
        editDiv.appendChild(btnSave)
        btnCanc = document.createElement("BUTTON")
        btnCanc.innerHTML = "<i class='fa fa-times' style=#{ if target.dataset.fieldType == 'textarea' then '' else 'padding-right:0px' }></i>#{ if target.dataset.fieldType == 'textarea' then ' Annulla' else '' }"
        btnCanc.className = "button tooltip is-transparent is-borderless is-radiusless"
        btnCanc.dataset.tooltip = "Annulla"
        btnCanc.dataset.action = "click->users#abort"
        editDiv.appendChild(btnCanc)
      else
        if form
          buttons = document.createElement("DIV")
          buttons.className = 'buttons'
          btnSave = document.createElement("BUTTON")
          btnSave.innerHTML = "<i class='fa fa-save' style=#{ if target.dataset.fieldType == 'textarea' then '' else 'padding-right:0px' }></i>#{ if target.dataset.fieldType == 'textarea' then ' Salva' else '' }"
          btnSave.className = "button tooltip is-transparent is-borderless is-radiusless"
          btnSave.dataset.tooltip = "Salva"
          # btnSave.dataset.action = "click->users#update"
          btnSave.type = 'submit'
          btnSave.dataset.userId = userId
          buttons.appendChild(btnSave)
          btnCanc = document.createElement("BUTTON")
          btnCanc.innerHTML = "<i class='fa fa-times' style=#{ if target.dataset.fieldType == 'textarea' then '' else 'padding-right:0px' }></i>#{ if target.dataset.fieldType == 'textarea' then ' Annulla' else '' }"
          btnCanc.className = "button tooltip is-transparent is-borderless is-radiusless"
          btnCanc.dataset.tooltip = "Annulla"
          btnCanc.dataset.action = "click->users#abort"
          buttons.appendChild(btnCanc)
          form.appendChild(buttons)
          editDiv.appendChild(form)
      target.classList.add('is-hidden')

  update: (event) =>
    target = event.currentTarget
    editor = target.parentNode
    container = editor.parentNode
    link = container.querySelector(".editable")
    input = editor.querySelector(".editor")
    value = input.value
    user_id = target.dataset.userId
    url = "/utenti/#{ user_id }"
    if input.checkValidity()
      Rails.ajax
        type: "PUT"
        url: "#{ url }"
        data: "#{ input.name }=#{ value }"
        success: (data, status, xhr) =>
          this.send('Salvataggio avvenuto correttamente!')
          this.removeElement target
          if link.dataset.fieldType == 'date'
            value = new Date(value).toLocaleDateString(window.navigator.language || 'it', { day: '2-digit', month: '2-digit', year: 'numeric' })
          else if link.dataset.fieldType == 'password'
            value = '*********'
          link.innerHTML = if value != '' then value else ( if link.dataset.fieldPlaceholder then link.dataset.fieldPlaceholder else 'aggiungi')
        error: (error) =>
          text = error
          try
            text = text.error
          if text
            this.send(text, 'error')
          else
            this.send('Si è verificato un errore durante il salvataggio!', 'error')
          this.removeElement target
    else
      if input.hasAttribute('pattern')
        this.send "Immettere un valore compreso tra #{input.pattern}!", 'error'
      else
        this.send 'Valore immesso non valido!', 'error'

  abort: (event) =>
    this.removeElement event.currentTarget

  removeElement: (target) =>
    if target.closest('.editor')
      editor = target.closest('.editor')
      container = editor.parentNode
      editor.remove()
      link = container.querySelector('.editable.is-hidden') if container
      link.classList.remove('is-hidden') if link
