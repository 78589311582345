import { Controller } from "stimulus"
import DualListbox from 'dual-listbox/dist/dual-listbox'
import PageController from './page_controller'

export default class extends PageController
  @targets = [ "container", "listbox" ]

  connect: =>
    if this.hasListboxTarget
      select = this.listboxTarget
      listbox = new DualListbox select,
        availableTitle: 'Rischi disponibili'
        selectedTitle: 'Rischi selezionati'
        addButtonText: '>'
        removeButtonText: '<'
        addAllButtonText: '>>'
        removeAllButtonText: '<<'